import React, { useMemo } from "react";
import heb from "../languages/heb";
import eng from '../languages/eng';
import { Labels } from "../languages";

const labelContext = React.createContext<Labels | null>(null);

const LabelsContextProvider = ({children}: {children: React.ReactNode}) => {
    const labels = useMemo(() => {
        return eng();
    }, []);

    return <labelContext.Provider value={labels}>{children}</labelContext.Provider>
}

const useLabelsContext = () => {
    const context = React.useContext(labelContext);

    if(!context) {
        throw("No Labels");
    }

    return context;
}

export {LabelsContextProvider, useLabelsContext};