import axios from 'axios'
import { apiError, apiStart, apiEnd, accessDenied, reLocation } from '../action/api'
import { Dispatch, MiddlewareAPI } from 'redux'
import { Account } from '../dto/store.types'

const apiMiddleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
    (next: (action: unknown) => unknown) =>
    (action: any) => {
        next(action)
        if (action?.metaData?.api?.url) {
            const userAccount = getState().currentAccount;
            return handleApiAction(action, dispatch, userAccount)
        }
    }

const handleApiAction = (action: any, dispatch: Dispatch, userAccount?: Account) => {
    if (action.metaData.api) {
        apiRequest(action, dispatch, userAccount)
    }
}

const apiRequest = async (action: any, dispatch: Dispatch, userAccount?: Account) => {
    const { url, method, data, onSuccess, onFailure, label, shouldThrow } = action.metaData.api;

    try {
        handleCookieLogin()
        handleApiRequestStart(dispatch, label)
        axios.defaults.headers.common['finbotUserAccountId'] = userAccount?.id
        const response = await axios({ url: `${getBaseDomain()}${url}`, method, data, withCredentials: true })
        const actionData = onSuccess(response.data)
        const timestamp = Date.now()

        dispatch({
            ...actionData,
            meta: { timestamp },
        })
        return actionData.payload
    } catch (error) {
        handleApiException(error, dispatch, onFailure)
        if (shouldThrow) {
            throw error
        }
    } finally {
        handleApiRequestEnd(dispatch, label)
    }
}

const handleApiRequestStart = (dispatch: Dispatch, label: any) => {
    label && dispatch(apiStart(label))
}

const handleApiRequestEnd = (dispatch: Dispatch, label: any) => {
    label && dispatch(apiEnd(label))
}

const handleApiException = (exception: any, dispatch: Dispatch, onFailure: any) => {
    dispatch(apiError(exception))
    dispatch(onFailure(exception))
    switch (exception?.response?.status) {
        case 401:
            dispatch({
                type: '',
                payload: {},
            })
            break
        case 403:
            console.log('test')
            dispatch(accessDenied(''))
            break
        default:
            return ''
    }
}

const getBaseDomain = () => {
    const { hostname, protocol, port } = window.location
    return `${protocol}//api.${hostname.replace(/^[^.]+\.(.+)/, '$1')}${port === '3000' ? `:${3003}` : ''}`
}

const handleCookieLogin = () => {
    const accessToken = document.cookie.match('(^|;)\\s*' + 'access_token' + '\\s*=\\s*([^;]+)')?.pop() || undefined
    if (!accessToken) {
        reLocation()
    }
}

export default apiMiddleware
