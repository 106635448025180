import { Box, LinearProgress, Typography } from "@mui/material";
import styled from "styled-components";

export const StockMarketContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: rgb(7,13,30);
    background: linear-gradient(90deg, rgba(7,13,30,1) 0%, rgba(9,14,31,1) 38%);
`; 

export const UserMenuMenu = styled.div`
    width: 100%;
    height: 10vh;
`;

export const GameDisplay = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const CachedOutContainer = styled.div`
    width: 20%;
`;

export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 20%;
    overflow: scroll;
`;

export const ChatTitle = styled.div`
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5%;
`;

export const ChatList = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #ededed30;
    justify-content: space-between;
    padding: 1px;
    margin: 0px 5px;
`;

export const ChatPlayer = styled.div`
    
`;

export const ChatContent = styled.div`

`;

export const GameChatViewContainer = styled.div`
    width: 33%;
`;

export const GameChartViewContainer = styled.div<{isMobile: boolean}>`
    width: ${props => (props.isMobile ? '60%' : '33%')};
`;

export const GameNewsContainer = styled.div`
    width: 33%;
`;

export const GameAction = styled.div`
    background: rgb(33,33,33);
    background: linear-gradient(90deg, #212121 0%, #343434 35%);
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    flex-direction: row;
`;

export const CoinsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
`;

export const GameActionContainer = styled.div`
    width: 20%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

export const SelectedSymbolContainer = styled.div`
    width: 30%;
    width: 30%;
    background-color: red;
`;

export const AccountInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const AccountInfo = styled.div`
    border-radius: 10px;
    border: 2px solid red;
`;

export const UserInfo = styled.div`
    width: 20%;
    margin-top: 1%;
`;

export const UserAnalyticsContainer = styled.div`
    border: 5px solid rgb(65 62 62);
    width: 120px;
    border-radius: 10px;
    margin: auto;
    background-color: black;
    color: white;
    direction: ltr;
    display: flex;
    flex-direction: column;
    padding: 3px;
`

export const GameActionDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 60%;
`;

export const ActionButtonGameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const SellButton = styled.button`
    cursor: pointer;
    border: 5px solid rgb(65 62 62);
    border-right-style: none;
    font-family: "system-ui";
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    transition: 2s;
    width: 335px;
    height: 70px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 0px 0px 0px 25px;
    background: linear-gradient(51deg, rgb(0, 0, 0) 0%, rgb(0, 87, 25) 36%);
`;

export const BlurSellButton = styled.div`
    position: absolute;
    left: 1px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff38;
    left: 20px;
    width: 115%;
    top: -3px;
    height: 115%;
    border-radius: 0px 0px 0px 20px;
    background-color: rgb(0 112 33);
`;

export const BuyButton = styled.button`
    cursor: pointer;
    border: 5px solid rgb(65 62 62);
    border-left-style: none;
    font-family: "system-ui";
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    transition: 2s;
    width: 335px;
    height: 70px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 0px 0px 25px;
    background: linear-gradient(303deg, rgb(0, 0, 0) 0%, rgb(176, 11, 11) 100%);
`;

export const BlurBuyButton = styled.div`
    position: absolute;
    left: 1px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff38;
    left: 20px;
    width: 115%;
    top: -3px;
    height: 115%;
    border-radius: 0px 0px 20px;
    background-color: #ff020261;
`;

export const TextButton = styled.div`
    font-weight: bold;
    text-shadow: 1px 1px 2px #000000;
    font-size: 15px;
`;

export const StrengthBarWrapper = styled(Box)({
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '5px solid rgb(65 62 62)',
});

  
export const StyledLinearProgress = styled(LinearProgress)({
    width: '100%',
    height: '10px',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#410000',
    },
});
  
export const PercentageLabel = styled(Typography)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bold',
    padding: '0 10px',
});
