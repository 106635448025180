import React from 'react';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';

interface MuiTypographyProps extends TypographyProps {
  children: React.ReactNode;
}

const MuiTypography: React.FC<MuiTypographyProps> = ({ variant, children, ...props }) => {
  return (
    <Typography dir="rtl" variant={variant} {...props}>
      {children}
    </Typography>
  );
};

export default MuiTypography;
export type { MuiTypographyProps };
