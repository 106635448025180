import * as S from './style';

const Coin = ({children, size, color}: CoinProps) => {
    return (
        <S.Coin size={size} color={color}>
            {/* <S.CoinNumber>{children}</S.CoinNumber> */}
            {children}
        </S.Coin>
    )
}

export default Coin;

type CoinProps = {
    children?: React.ReactNode;
    size?: string;
    color?: string;
}