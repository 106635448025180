export const LabelsReducer = {
    redux: {
        SET_SYSTEM_SETTING: 'SET_SYSTEM_SETTING',
        GET_SYSTEM_SETTING: 'GET_SYSTEM_SETTING',
        SET_DATA_ROWS: 'SET_DATA_ROWS',
        GET_DATA_ROWS: 'GET_DATA_ROWS',
        SET_DATA_GET_ACCOUNTS: 'SET_DATA_GET_ACCOUNTS',
        GET_DATA_GET_ACCOUNTS: 'GET_DATA_GET_ACCOUNTS',
        SET_DATA_CREATE_CONNECTION: 'SET_DATA_CREATE_CONNECTION',
        GET_DATA_CREATE_CONNECTION: 'GET_DATA_CREATE_CONNECTION',
        SET_DATA_CALLBACK_CONNECTION: 'SET_DATA_CALLBACK_CONNECTION',
        GET_DATA_CALLBACK_CONNECTION: 'GET_DATA_CALLBACK_CONNECTION',
        SET_DATA_SAVE_ACCOUNTS: 'SET_DATA_SAVE_ACCOUNTS',
        GET_DATA_SAVE_ACCOUNTS: 'GET_DATA_SAVE_ACCOUNTS',
        SET_DATA_SYNC_ACCOUNTS: 'SET_DATA_SYNC_ACCOUNTS',
        GET_DATA_SYNC_ACCOUNTS: 'GET_DATA_SYNC_ACCOUNTS',
        SET_DATA_SYNC_SPEC_ACCOUNT: 'SET_DATA_SYNC_SPEC_ACCOUNT',
        GET_DATA_SYNC_SPEC_ACCOUNT: 'GET_DATA_SYNC_SPEC_ACCOUNT',
        SET_ACCOUNT: 'SET_ACCOUNT',
        GET_CARDS_SORT_CODE: 'GET_CARDS_SORT_CODE',
        SET_CARDS_SORT_CODE: 'SET_CARDS_SORT_CODE',
        SET_CARD_SORT_CODES: 'SET_CARD_SORT_CODES',
        SET_CARD_ACCOUNTING_SECTIONS: 'SET_CARD_ACCOUNTING_SECTIONS',
        SET_CARD_PARENT_SECTIONS: 'SET_CARD_PARENT_SECTIONS',
        SET_CHANGE_ACCOUNT_STATUS_OFB: 'SET_CHANGE_ACCOUNT_STATUS_OFB',
        GET_CHANGE_ACCOUNT_STATUS_OFB: 'GET_CHANGE_ACCOUNT_STATUS_OFB',
        GET_ADD_CARD_ID_TO_ACCOUNT_OFB: 'GET_ADD_CARD_ID_TO_ACCOUNT_OFB',
        SET_ADD_CARD_ID_TO_ACCOUNT_OFB: 'SET_ADD_CARD_ID_TO_ACCOUNT_OFB',
        GET_ADD_NICKNAME_TO_ACCOUNT_OFB: 'GET_ADD_NICKNAME_TO_ACCOUNT_OFB',
        SET_ADD_NICKNAME_TO_ACCOUNT_OFB: 'SET_ADD_NICKNAME_TO_ACCOUNT_OFB',
        GET_DELETE_ACCOUNT_OFB: 'GET_DELETE_ACCOUNT_OFB',
        SET_DELETE_ACCOUNT_OFB: 'SET_DELETE_ACCOUNT_OFB',
        GET_ACCOUNTS: 'GET_ACCOUNTS',
        GET_SORTCODE_CARD: 'GET_SORTCODE_CARD',
        SET_SORTCODE_CARD: 'SET_SORTCODE_CARD',
        ADD_NEW_CARD_TAB: 'ADD_NEW_CARD_TAB',
        CLOSE_CARD_TAB: 'CLOSE_CARD_TAB',
        GET_CARDS: 'GET_CARDS',
        GET_RECENT_CARDS: 'GET_RECENT_CARDS',
        SET_RECENT_CARDS: 'SET_RECENT_CARDS',
        GET_CARD_MOVEMENTS: 'GET_CARD_MOVEMENTS',
        SET_DATA_GET_TRANSACTIONS: 'SET_DATA_GET_TRANSACTION',
        GET_DATA_GET_TRANSACTIONS: 'GET_DATA_GET_TRANSACTION',
        SET_DATA_GET_TRANSACTIONS_BY_SEARCH: 'SET_DATA_GET_TRANSACTIONS_BY_SEARCH',
        GET_DATA_GET_TRANSACTIONS_BY_SEARCH: 'GET_DATA_GET_TRANSACTIONS_BY_SEARCH',
        GET_DATA_GET_USERS: 'GET_DATA_GET_USERS',
        SET_DATA_GET_USERS: 'SET_DATA_GET_USERS',
        GET_DATA_OFB_ACCOUNT_BY_USER: 'GET_DATA_OFB_ACCOUNT_BY_USER',
        SET_DATA_OFB_ACCOUNT_BY_USER: 'SET_DATA_OFB_ACCOUNT_BY_USER',
        GET_JOURNAL_ENTRY: 'GET_JOURNAL_ENTRY',
        SET_JOURNAL_ENTRY: 'SET_JOURNAL_ENTRY',
        GET_LISTING_HOUSE: 'GET_LISTING_HOUSE',
        SET_LISTING_HOUSE: 'SET_LISTING_HOUSE',
        GET_CURRENCY: 'GET_CURRENCY',
        SET_CURRENCY: 'SET_CURRENCY'
    },
    api: {
        GET: 'get',
        POST: 'post',
        PUT: 'put',
        DELETE: 'delete',
        PATCH: 'patch'
    },
    card: {
        GET_CARD_DATA: 'GET_CARD_DATA',
        GET_ALL_CARDS_BY_TYPE: 'GET_CARD_DATA_BY_TYPE',
        SET_ALL_CARDS_BY_TYPE: 'SET_CARD_DATA_BY_TYPE',
        SET_CREATE_NEW_CARD: 'SET_CREATE_NEW_CARD',
        GET_CREATE_NEW_CARD: 'GET_CREATE_NEW_CARD',
        SET_UPDATE_CARD: 'SET_UPDATE_CARD',
        GET_UPDATE_CARD: 'GET_UPDATE_CARD',
        SET_TEMP_CARD: 'SET_TEMP_CARD',
        SET_DELETE_CARD: 'SET_DELETE_CARD',
    },
    reducer_api_type: {
        api: 'API',
        start: 'API_START',
        end: 'API_END',
        denied: 'ACCESS_DENIED',
        error: 'API_ERROR',
        front_only: 'FRONT_END_ONLY'
    },
    itc_six_tickets: {
        GET_ITC_SIX_TICKETS: 'GET_ITC_SIX_TICKETS',
        SET_ITC_SIX_TICKETS: 'SET_ITC_SIX_TICKETS',
    },
    card_tags: {
        GET_CARD_TAGS: 'GET_CARD_TAGS',
        SET_CARD_TAGS: 'SET_CARD_TAGS',
    }
}

