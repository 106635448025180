import { LabelsReducer } from "../../constant/LabelsReducer";
import { Card } from "../dto/store.types";

export function tempCard(state: Card | {} = {}, action: any) {        
    switch(action?.type){
        case LabelsReducer.card.SET_TEMP_CARD:            
            return {...action.payload};
        default: 
            return state;
    }
}