import { Box, LinearProgress, Typography } from '@mui/material';
import MuiButton from '../../components/inputs/Button/Button';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useEffect, useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import * as S from './StocksMarket.style';
import MobileActionButtons from './MobileActionButtons/MobileActionButtons';
import { useLabelsContext } from '../../context/language.context';
import MuiTypography from '../../components/inputs/Typography/Typography';
import Coin from '../../components/inputs/Coin/Coin';

const StocksMarket = () => {
    const isMobile = useIsMobile();
    const {stocks} = useLabelsContext();
    const [options, setOptions] = useState<any>(optionChart);
    const totalStrength = 50 + 50;
    const leftPercentage = (50 / totalStrength) * 100;
    const rightPercentage = (50 / totalStrength) * 100;

    console.log(isMobile)

    useEffect(() => {
        handleTest();
    }, [])

    const handleTest = async () => {
        const data = await fetch(
            'https://demo-live-data.highcharts.com/aapl-ohlcv.json'
        ).then(response => response.json());
    
        const ohlc = [],
        volume = [],
        dataLength = data.length;
    
        for (let i = 0; i < 100; i += 1) {
            ohlc.push([
                data[i][0], // the date
                data[i][1], // open
                data[i][2], // high
                data[i][3], // low
                data[i][4] // close
            ]);
    
            volume.push([
                data[i][0], // the date
                data[i][5] // the volume
            ]);
        }

        options.series[0].name = 'asdsad';
        options.series[0].data = ohlc;
        setOptions({...options});
    }


    return (
        <S.StockMarketContainer>
            <S.GameDisplay>
                <S.CachedOutContainer>
                    <S.ChatTitle>
                        <MuiTypography sx={{margin: 'auto', color: '#9c17cb'}}>
                            CASHED OUT
                        </MuiTypography>
                    </S.ChatTitle>
                </S.CachedOutContainer>     
                <S.GameChartViewContainer isMobile={isMobile}>
                    <HighchartsReact 
                        allowChartUpdate 
                        containerProps={{ style: { height: "60vh", width: '100%', margin: "0 auto" } }} 
                        highcharts={Highcharts} 
                        constructorType={'stockChart'} 
                        options={options} 
                    />
                </S.GameChartViewContainer>
                <S.ChatContainer>
                    <S.ChatTitle>
                        <MuiTypography sx={{margin: 'auto', color: '#9c17cb'}}>
                            LIVE CHAT
                        </MuiTypography>
                    </S.ChatTitle>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                    <S.ChatList>
                        <S.ChatContent>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                Hey u there
                            </MuiTypography>
                        </S.ChatContent>
                        <S.ChatPlayer>
                            <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                bar
                            </MuiTypography>
                        </S.ChatPlayer>
                    </S.ChatList>
                </S.ChatContainer>
            </S.GameDisplay>
            <S.GameAction>
                <S.UserInfo>
                    <S.UserAnalyticsContainer>
                        <MuiTypography dir='ltr' sx={{fontSize: '12px', color: '#ffffff70'}}>
                            {stocks.portfolio}
                        </MuiTypography>
                        <MuiTypography dir='ltr' sx={{color: '#ffffff70'}}>
                            {'$3000'}
                        </MuiTypography>
                    </S.UserAnalyticsContainer>
                </S.UserInfo>
                <S.GameActionDetails>
                    <S.StrengthBarWrapper>
                        <S.StyledLinearProgress variant="determinate" sx={{
                            height: '20px',
                            background: '#013b0e',
                            }} value={leftPercentage} />
                        <S.PercentageLabel style={{ left: 0 }}>
                            {Math.round(leftPercentage)}%
                        </S.PercentageLabel>
                        <S.PercentageLabel style={{ right: 0 }}>
                            {Math.round(rightPercentage)}%
                        </S.PercentageLabel>
                    </S.StrengthBarWrapper>
                    {!isMobile ? 
                        <S.ActionButtonGameContainer>
                            <S.BuyButton>
                                <S.BlurBuyButton>
                                    <S.TextButton>
                                        Buy
                                    </S.TextButton>
                                </S.BlurBuyButton>
                            </S.BuyButton>
                            <S.SellButton>
                                <S.BlurSellButton>
                                    <S.TextButton>
                                        Sell
                                    </S.TextButton>
                                </S.BlurSellButton>
                            </S.SellButton>
                        </S.ActionButtonGameContainer>
                    : <MobileActionButtons />
                    }
                    <S.CoinsContainer>
                        <Coin size='35px' color='blue'>
                            1
                        </Coin>
                        <Coin size='40px' color='#c100bd'>
                            50
                        </Coin>
                        <Coin size='42px' color='blue'>
                            100
                        </Coin>
                        <Coin size='50px' color='green'>
                            1000
                        </Coin>
                        <Coin size='42px' color='red'>
                            500
                        </Coin>
                        <Coin size='40px' color='black'>
                            20
                        </Coin>
                        <Coin size='35px' color='#9c17cb'>
                            5
                        </Coin>
                    </S.CoinsContainer>
                </S.GameActionDetails>
                <S.GameActionContainer>
                    <S.SelectedSymbolContainer>
                        Test icon
                    </S.SelectedSymbolContainer>
                    <S.AccountInfoContainer>
                        <S.AccountInfo>
                            Balance: $1000 
                        </S.AccountInfo>
                        <S.AccountInfo>
                            Balance: $1000 
                        </S.AccountInfo>
                    </S.AccountInfoContainer>
                </S.GameActionContainer>
            </S.GameAction>
        </S.StockMarketContainer>
    )
}

export default StocksMarket;

const optionChart = {
    exporting: {
        enabled: false,
    },
    //Chart style
    chart: {
        //backgroundColor: "#000000",
        backgroundColor: "rgba(0,0,0,0)"
    },

    //Navigation
    navigator: {
        enabled: false,
    },

    //Scroll bar
    scrollbar: {
        enabled: false,
    },

    rangeSelector: {
        enabled: false
    },

    xAxis: {
        visible: false,
    },

    yAxis: {
        visible: false,
    },

    series: [
        {
        name: '',
        type: "area",
        data: [], 
        
        gapSize: 5,
        tooltip: {
            valueDecimals: 2,
        },
        fillColor: {
            linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            },
            stops: [
            [0, Highcharts.color("#690e51").setOpacity(1).get("rgba")],
            [1, Highcharts.color("#690e51").setOpacity(0).get("rgba")],
            ],
        },
        threshold: null,
        }
    ],
}
