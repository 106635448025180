const ThemeProperties = () => {
    return {
        button: {
            color: {
                primary: 'primary',
                secondary: 'secondary',
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning',
            },
            size: {
                small: 'small',
                medium: 'medium',
                large: 'large',
                string: 'string'
            },
            variant: {
                contained: 'contained',
                outlined: 'outlined',
                text: 'text'
            }
        },
        textField: {
            color: {
                primary: 'primary',
                secondary: 'secondary',
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning'
            },
            type: {
                email: 'email',
                password: 'password',
                text: 'text',
                number: 'number',
                date: 'date'
            }
        },
        autocomplete: {
            color: {
                primary: 'primary',
                secondary: 'secondary',
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning'
            },
        },    
        checkbox: {
            color: {
                primary: 'primary',
                secondary: 'secondary',
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning'
            },
        },
        palette: {
            mode: 'light',
            primary: {
                main: '#F75C33',
            },
            secondary: {
                main: '#263238',
            },
            success: {
                main: '#4caf50',
            },
            error: {
                main: '#D32F2F',
            },
            info: {
                main: '#0288D1',
            },
            warning: {
                main: '#EF6C00',
            },
            text: {
                primary: '#151414',
                secondary: '#707181',
                disabled: '#ABADC4',
            },
            background: {
                default: '#FAFAFA',
                paper: '#FFF5F3'
            },
        },
        typography: {
            fontFamily: ' "Liebling Pro",   "Noto Sans Hebrew", "sans-serif"',
            variant: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',
                subtitle1: 'subtitle1',
                subtitle2: 'subtitle2',
                body1: 'body1',
                body2: 'body2',
                button: 'button',
                caption: 'caption',
                overline: 'overline'
            }
        },
        direction: 'rtl',
        globalColors: {
            yellow: '#ceff6d',//success.light
            blue: '#0288D1',//info.main
            lightGray: '#fafafa',//background.default
            green: '#87CE1F',//success.main
            white: '#ffffff',//primary.contrastText
            mainText: '#263238',//secondary.light
            primaryOrange: '#F75C33',//primary.main
            red: '#FF0F0F',//custom.red
            secondaryRed: '#D32F2F', 
            redBgc: '#d32f2f4c',
            mainGray: '#BDC8C9',//custom.mainGray
            secondGray: '#15141499',//custom.secondGray
            hoverGray: '#1514140a',//custom.hoverGray
            borderGray: '#2632383b',//custom.borderGray
            borderGraySec: '#0000001e',//custom.borderGraySec
            iconGray: '#7c7c7c',//custom.iconGray
            overlay: '#00000082',//custom.overlay
            backgroundGray: '#F5F5F5',//custom.backgroundGray,
            backgroundGray2: '#15141414',
        }
    }
}
export default ThemeProperties;