import { LabelsReducer } from "../../constant/LabelsReducer";
import { Account } from "../dto/store.types";

const defaultAccount = new Account();
export default function currentAccount(state: Account = defaultAccount, action: any) {
    switch(action?.label) {
        case LabelsReducer.redux.SET_ACCOUNT:
            return setAccount(state, action.data);
        default:
            return state;
    }
}

const setAccount = (accountState: Account, selectedAccount: Account) => {
    return {...accountState, ...selectedAccount};
}
