export const ROUTES = {
    home: {
        main: '/'
    },
    games: {
        stockMarket: 'stocks'
    },
    auth: {
        login: 'login',
        forgotPassword: 'forgot-password'
    },
}
