import React from 'react';
import * as S from './MobileActionButtons.style';

const MobileActionButtons = () => {
    return (
        <S.ActionButtonGameContainer>
            <S.BuyButton>
                <S.BlurBuyButton>
                    <S.TextButton>
                        Buy
                    </S.TextButton>
                </S.BlurBuyButton>
            </S.BuyButton>
            <S.SellButton>
                <S.BlurSellButton>
                    <S.TextButton>
                        Sell
                    </S.TextButton>
                </S.BlurSellButton>
            </S.SellButton>
        </S.ActionButtonGameContainer>
    )
}

export default MobileActionButtons;