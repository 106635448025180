import { Route, Routes } from "react-router-dom"
import { RoutesLabels } from "../constant";
import StocksMarket from "../game/StocksMarket/StocksMarket.game";

const MainRoute = ({games: {stockMarket}}: RoutesLabels) => {
    return (
        <Routes>
            <Route path={`${stockMarket}`} element={<><StocksMarket /></>} />
        </Routes>
    )
}

export default MainRoute;