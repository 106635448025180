export interface IReduxRow {
    id: number;
}

export interface IReduxRowForOfb {
    id: string | null;
}

export class Bank implements IReduxRow {
    id = 0;
    name?: string;
    branch?: string;
    address?: string;
}

export class Card implements IReduxRow {
    id = 0;
    status?: number | 1;
    card?: string;
    name?: string;
    menus?: any[];
    number?: number | null;
    sortCodeId?: number | null;
    institutionalType?: number | null;
    vatRate?: number | null;
    irsRate?: number | null;
    pcnType?: string | null;
    companyType?: number | null;
    addressStreet?: string | null;
    addressNum?: string | null;
    addressCity?: string | null;
    addressCountry?: string | null;
    phone?: string | null;
    isTaxDeduction?: number | null;
    isDeductionBooksConfirmation?: number | null;
    deductionRate?: number | null;
    deductionType?: number | null;
    deductionDueDate?: Date | null;
    deductionBooksConfirmation?: Date | null;
    plRate?: number | null;
    termsOfPayment?: number | null;
    created?: Date;
    cardContacts?: CardContact[] | null;
    cardNotes?: CardNote[] | null;
    tags?: CardTag[] | null;
    reminders?: Reminder[] | null;
    generalIndex?: number | 0;
    subType?: number | 0;
    isDefault?: number | 0;
    recognizeAsExpense?: number | null;
    includeVatReport?: number | null;
    includeAdvancesReport?: number | null;
    includeDeductionReport?: number | null;
    vatNumber?: string;
    i6111Id?: number | null;
}

export class Reminder {
    id?: number | 0;
    text?: string;
    cronExpression?: string;
    active?: number | 1;
    type?: number | 0;
    date?: string | null;
    created?: Date | null;
}

export class CardContact {
    firstName?: string | null;
    lastName?: string | null;
    role?: string | null;
    email?: string | null;
    phone?: string | null;
    autoSendDocs?: number | null;
}

export class CardNote {
    note?: string | null;
    active?: number | 1;
    created?: Date | null;
}

export class CardTag {
    id?: number | null;
    userAccountId?: number;
    name?: string | null;
    active?: string | 1;
    created?: string | null;
}
export class GetAccounts implements IReduxRow {
    id: number = 0;
    connectionId?: string | null;
    accountId?: string | null;
    cardId?: number | null | undefined;
    userId?: number | null;
    ofbUserId?: string | null;
    ofbEmail?: string | null;
    providerId?: string | null;
    nickname?: string | null;
    externalTicketNum?: string | null;
    accountNumber?: string | null;
    branchNum?: number | null;
    bankNum?: number | null;
    bankAccountNumber?: number | null;
    accountType?: string | null;
    product?: string | null;
    currency?: string | null;
    ownerFullname?: string | null;
    transactionsNum?: number | null;
    ofbCreatedAt?: string | null;
    ofbUpdatedAt?: string | null;
    createAt?: Date | null;
    updatedAt?: Date | null;
    active?: number | null;
    changedActiveDate?: Date | null;
    changedActiveBy?: number | null;
    type?: string | null;
    comments?: string | null;
    card?: { id?: number, number?: number, name?: string } | null | undefined;
    status?: number;
    lastUpdateTransactions?: Date | null | undefined;
}

export class CreateConnection implements IReduxRowForOfb {
    id = null;
    connectUrl?: string;
}

export class CallbackConnection implements IReduxRow {
    id = 11;
    isCallback?: boolean;
}

export class SaveAccounts implements IReduxRow {
    id = 12;
    success?: number | undefined;
    successMessage?: string | undefined;
    failed?: number | undefined;
    failedMessage?: string | undefined;
}

export class SyncAccounts implements IReduxRow {
    id = 13;
    success?: number | undefined;
    successMessage?: string | undefined;
    failed?: number | undefined;
    failedMessage?: string | undefined;
}

export class SyncSpecAccount implements IReduxRow {
    id = 14;
    success?: number | undefined;
    successMessage?: string | undefined;
    failed?: number | undefined;
    failedMessage?: string | undefined;
}

export class ChangeAccountStatusOfb implements IReduxRow {
    id = 14;
    success?: number | undefined;
    successMessage?: string | undefined;
    failed?: number | undefined;
    failedMessage?: string | undefined;
}

export class Account {
    id = 0;
    companyId?: number;
    name?: string;
    type?: number;
    companyLogo?: string
}

export class GetAllCardsByType {
    id = 17;
    number?: string;
    active?: number;
    name?: string;
    institutionalType?: number | null;
    vatRate?: number | null;
    irsRate?: number | null;
    pcnType?: string | null;
    companyType?: number | null;
    addressStreet?: string | null;
    addressNum?: string | null;
    addressCity?: string | null;
    addressCountry?: string | null;
    phone?: string | null;
    isTaxDeduction?: number | null;
    deductionRate?: number | null;
    deductionBooksConfirmation?: string | null;
    termsOfPayment?: number | null;
    created?: Date;
    sortCode?: { id?: number, sortCode?: number, name?: string }
}

export class AddCardIdToAccountOfb {
    id = 18;
}

export class AddNicknameToAccountOfb {
    id = 19;
}

export type ListingHouse = {
    id: number;
    oldId: number;
    personalId: number;
    invoiceNum: string;
    shaamConfirmationNum: string | null;
    authanticateShaamConfirmationNum: number;
    personName: string;
    supplyId: number;
    expenseId: number;
    kupaId: number;
    userId: number;
    currAmount: string | null;
    currency: string | null;
    amountOfInvoice: string;
    invoiceContent: string;
    vatCalc: string;
    insertVat: number;
    assetCals: string;
    isRecipt: number;
    postedDate: string;
    originPostDate: string;
    outcomeValDate: string;
    vatDate: string | null;
    status: number;
    suspectDupId: number;
    cpaCheck: "0" | "1";
    createdAt: number | null;
    zipFolderId: number;
    listingOfficeNum: string;
    supplyAddedByUser: boolean;
    listingSyncWithOfiice: number;
    type: number;
    zapier: number;
    addedManual: number;
    listingSupNum: string | null;
    listingPaymentId: number;
    isStorno: number;
    listingStornoSrcId: number;
    listingAlreadyRestore: boolean;
    exportedToRevhit: number;
    fixedAssetsCreated: boolean;
    uploadByAdminFromUser: boolean;
    movementType: string | null;
    outcomeTicketId: string | null;
    outcomeSplitFrom: number;
    folderId: number;
    stornoForFixReport: boolean;
    outcomeFileNameId: number;
    deductionConId: number;
    outcomeCrSymbol: string;
    outcomeFromWhatsapp: boolean;
    outcomeCrMovementType: string;
    outcomeExportToCr: boolean;
    isPettyCash: number;
    fromSelfInvoice: number;
    movindatExported: number;
    isPaid: number;
    isDiffCurrency: boolean;
    manualOutcomeFilePath: string | null;
    outcomePcnType: number;
    batchNum: number | null;
    exportedDate: Date | null;
    card: Card;
    supplier: Supplier;
}

export class GetTransactions implements IReduxRow {
    id: number = 0;
    bankReconciliationId?: number | null;
    providerId?: string | null;
    ofbAccounts?: {
        accountNumber?: string | null;
        nickname?: string | null;
    }
    description?: string | null;
    additionalInfo?: string | null;
    valueDate?: Date | null;
    originalAmountSum?: string | null;
    originalAmountCurrency?: string | null;
}

export class GetTransactionsBySearch implements IReduxRow {
    id: number = 0;
    bankReconciliationId?: number | null;
    providerId?: string | null;
    ofbAccounts?: {
        accountNumber?: string | null;
        nickname?: string | null;
    }
    description?: string | null;
    additionalInfo?: string | null;
    valueDate?: Date | null;
    originalAmountSum?: string | null;
    originalAmountCurrency?: string | null;
}

export type Currency = CurrencyRates & CurrencyTypes;

export type CurrencyRates = {
    id: number;
    currencyTypeId: number;
    value: number;
    createdDate: string;
    currencyType?: CurrencyTypes;
}

export type CurrencyTypes = {
    id: number;
    name: string;
    symbol: string;
    heName: string;
    currencyRates: CurrencyRates[];
}

export type Supplier = {
    id: number;
    generalIndexId: number;
    oldId: number;
    name: string;
    isActive: number;
    userId: number;
    expenseId: number;
    supplyNumber: string;
    supplyTicketNum: string;
    supplierEmail: string | null;
    supplierPhone: string | null;
    supplierCity: string | null;
    supplierStreet: string | null;
    supplierDetails: string | null;
    supplierReportType: number;
    deductionRate: number | null;
    supplierHaveBooks: number | null;
    supplierBankName: string | null;
    supplierBankBranch: string | null;
    supplierBankNumber: string | null;
    supplierBankAccountName: string | null;
    dateAdded: Date;
    isDeductionMandatory: number;
    deductionExpiredDate: string | null;
    deductionType: number;
    externalSystemExists: number | null;
    sortCodeType: number | null;
    listingHouse: ListingHouse[];
}