
import { Color, Palette, PaletteOptions } from '@mui/material';
import { createTheme, ThemeOptions, alpha } from '@mui/material/styles';


export interface CustomColors extends Color {
  custom: string;
  red: string;
  redBgc: string;
  mainGray: string;
  secondGray: string;
  hoverGray: string;
  borderGray: string;
  borderGraySec: string;
  iconGray: string;
  overlay: string;
  backgroundGray: string;
  backgroundGray2: string;
  statusGray: string;
  orangeBgc: string;
  greenBgc: string,
}

export const custom: CustomColors = {
  custom: '#FFFFFF',
  red: '#FF0F0F',
  redBgc: '#d32f2f4c',
  mainGray: '#BDC8C9',
  secondGray: '#15141499',
  hoverGray: '#1514140a',
  borderGray: '#2632383b',
  borderGraySec: '#0000001e',
  iconGray: '#7C7C7C',
  overlay: '#00000082',
  backgroundGray: '#F5F5F5',
  backgroundGray2: '#15141414',
  statusGray: '#7D868A',
  orangeBgc: '#FEF7F0',
  greenBgc:'#EFFBE9',
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: ''
}

interface MyPaletteExtensions {
  custom: CustomColors;
}

declare module '@mui/material/styles' {
  interface Palette extends MyPaletteExtensions { }
  interface PaletteOptions extends MyPaletteExtensions { }
}


const GREY = {
  0: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#FBFBFB',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      red: string,
      redBgc: string
      mainGray: string,
      secondGray: string,
      hoverGray: string,
      borderGray: string,
      borderGraySec: string,
      iconGray: string,
      overlay: string,
      backgroundGray: string,
      backgroundGray2: string;
      statusGray: string;
      orangeBgc: string;
      greenBgc: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      red: string,
      redBgc: string
      mainGray: string,
      secondGray: string,
      hoverGray: string,
      borderGray: string,
      borderGraySec: string,
      iconGray: string,
      overlay: string,
      backgroundGray: string,
      backgroundGray2: string;
      statusGray: string;
      orangeBgc: string;
      greenBgc: string;
    };
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#F75C33',
      dark: '#8F1011',
      light: alpha('#F75C33', 0.06),
      contrastText:'#FFF5F3',
      // hover: alpha('#F75C33', 0.4),
      // light: '#FA8F65',
    },
    secondary: {
      light: '#263238',
      main: '#263238',
    },
    success: {
      main: '#87CE1F',
      dark: '#64A510',
      light: '#CEFF6D',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
    },
    warning: {
      main: '#EF6C00',
      dark: '#EF6C00',
      light: '#F75C33',
    },
    text: {
      primary: alpha('#151414', 0.87),
      secondary: alpha('#151414', 0.6),
      disabled: alpha('#151414', 0.38),
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFF'
    },
    common: {
      white: '#FFF',
    },
    grey: GREY,
    custom,
    // divider: GREY[500_12],
    divider: alpha('#000', 0.12),
    action: {
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      activatedOpacity: 0.12,
    },
  },
  typography: {
    fontFamily: '"Noto Sans Hebrew", "Liebling Pro",  "sans-serif"',
  },
  direction: 'rtl',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  }
};
const theme = createTheme(themeOptions);
export default theme;