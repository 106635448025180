import { LabelsReducer } from "../../constant/LabelsReducer"

export const apiStart = (label: string) => ({
    type: LabelsReducer.reducer_api_type.start,
    label: label,
})

export const apiEnd = (label: string) => ({
    type: LabelsReducer.reducer_api_type.end,
    label: label,
})

export const accessDenied = (url: string) => ({
    type: LabelsReducer.reducer_api_type.denied,
    label: url,
})

export const apiError = (error: Error) => ({
    type: LabelsReducer.reducer_api_type.error,
    label: [],
})

export const frontOnly = (label: string) => ({
    type: LabelsReducer.reducer_api_type.front_only,
    label: label,
})

export const reLocation = () => {
    window.location.href = '/auth/login'
}
