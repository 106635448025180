import React, { useMemo } from "react";
import ThemeProperties from "../theme/theme.properties";
import { AppThemeProperties } from "../theme/index";

const themeContext = React.createContext<AppThemeProperties | null>(null);

const ThemeContextProvider = ({children}: {children: React.ReactNode}) => {
    const theme = useMemo(() => {
        return ThemeProperties();
    }, []);

    return <themeContext.Provider value={theme}>{children}</themeContext.Provider>
}

const useThemeContext = () => {
    const context = React.useContext(themeContext);

    if(!context) {
        throw new Error("No Theme");
    }

    return context;
}

export {ThemeContextProvider, useThemeContext};